import * as React from 'react'
import { graphql } from 'gatsby'

import EventsProvider from 'contexts/EventsContext'
import { Layout, EventCardList, SEO } from 'components'

const IndexPage = ({ data }) => {
  return (
    <EventsProvider>
      <Layout>
        <SEO title="Home" />
        <EventCardList data={data.allGoogleEventsSheet.edges} />
      </Layout>
    </EventsProvider>
  )
}

export const query = graphql`
  query {
    allGoogleEventsSheet(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date: date(formatString: "MMMM DD, YYYY")
          shortDate: date(formatString: "MMM DD, YYYY")
          time
          website
          title
          status
          pic4
          pic3
          pic2
          pic1
          location
          id
          description
        }
      }
    }
  }
`

export default IndexPage
